import Cookies from 'js-cookie'
import { path, equals } from 'ramda'
import moment from 'utils/moment'
// Project deps
import { getArtifacts, getCurrentProject } from 'modules/projects/selectors'
import { getGCPsForArtifact, getImportWizard } from 'modules/importWizard/selectors'
import { getCloneJobOptions, getCloneArtifactOptions, getSelectedTrajectoryArtifacts } from 'modules/pipelineWizard/selectors'
import { findById, makeUnique } from 'utils/list'
import { fromDecimalYear, toDecimalYear } from 'utils/dateTime'
import { withRounding } from 'utils/numeric'
import { getAntennas, getClassifications } from 'modules/app/selectors'
import { CRSFields } from 'templates/CRS/constants'
import { antennaInitialValue, LasSettings } from 'templates/constants'
import { getActiveUser, getLoggedUser } from 'modules/users/selectors'
import { getCompanyId } from 'utils/company'
import { getCompanyCalibrations } from 'modules/calibrations/selectors'

export function getClassificationsValues (state, extra) {
  const artifactProperties = getArtifactProperties(state, extra)
  const fileProperties = artifactProperties && artifactProperties.fileProperties
  const filesWithClassifications = Object.keys(fileProperties || {}).filter(key => {
    const filePropertiesOfFile = fileProperties[key] || {}
    return 'classification' in filePropertiesOfFile
  })
  const hasClassifications = filesWithClassifications.length > 0
  // Merge all classifications from all files into one array
  const allClassificationsFromFiles = filesWithClassifications.reduce((classifications, fileName) => {
    const classificationsFromFile = fileProperties[fileName].classification || []
    return [
      ...classifications,
      ...classificationsFromFile.reduce((all, classification) => {
        // keep only unique classifications
        if (!classifications.find(classif => +classif.index === +classification.index)) {
          return [
            ...all,
            classification,
          ]
        }
        return all
      }, []),
    ]
    // We check fileProperties for finding which classes are presented in the LAS files and show only those.
    // Unfortunately, we never populate statistics about classification for LAS files like DTM
    // and trajectories that are always in class 0. So it's better to always show the ability
    // to disable class 0 even though it does not exist to prevent accidental hiding of those entities.
  }, [{ index: '0' }])
  const allClassifications = getClassifications(state)
  return {
    hasClassifications,
    classifications: !hasClassifications
      ? allClassifications
      : Object.keys(allClassifications)
        .filter(classificationIndex => allClassificationsFromFiles.find(({ index }) => index === classificationIndex))
        .reduce((all, classificationIndex) => ({ ...all, [classificationIndex]: allClassifications[classificationIndex] }), {}),
  }
}

export function getLasTemplateFieldInitialValue (name, extraProps = {}, state, extra) {
  if (name === 'text') {
    return 'Please confirm the detected time format:'
  }
  if (name === 'time_format') {
    const { protoArtifact, timeFormat } = extraProps
    if (timeFormat) {
      return timeFormat['time_format']
    }
    const { properties } = protoArtifact
    const timeFormats = makeUnique(Object.keys(properties.fileProperties).reduce((allTimeFormats, fileName) => {
      const props = properties.fileProperties[fileName]
      return [
        ...allTimeFormats,
        path(['result', 'header', 'TimeFormat'], props),
      ]
    }, []).filter(Boolean))
    const [firstTimeFormat] = timeFormats
    if (firstTimeFormat) {
      return firstTimeFormat
    }
    return ''
  }
  if (name === 'number_of_files') {
    const { fileNames } = extraProps
    if (fileNames) {
      return fileNames.length
    }
    return 0
  }
  if (name === 'merge_files') {
    const { fileNames } = extraProps
    if (fileNames) {
      return fileNames.length > 1
    }
    return false
  }
  if (name === 'acquisition_date') {
    const { protoArtifact, timeFormat } = extraProps
    if (timeFormat) {
      const date = timeFormat['acquisition_date']
      return typeof date === 'string' ? moment.utc(date) : date
    }
    const { properties } = protoArtifact
    const times = Object.keys(properties.fileProperties).reduce((allTimeFormats, fileName) => {
      const props = properties.fileProperties[fileName]
      const time = path(['result', 'header', 'Time'], props)
      return [
        ...allTimeFormats,
        time,
      ]
    }, []).filter(Boolean).map(date => typeof date === 'string' ? moment.utc(date) : date)
      .sort((a, b) => a.isBefore(b) ? -1 : 1)
    const [firstTime] = times
    if (firstTime) {
      return typeof firstTime === 'string' ? moment(firstTime) : firstTime
    }
    return null
  }
  if (name === 'nir') {
    const { lasSettings } = extraProps
    if (lasSettings) {
      return lasSettings.nir
    }
    return LasSettings.nir
  }
  if (name === 'user_data') {
    const { lasSettings } = extraProps
    if (lasSettings) {
      return lasSettings.user_data
    }
    return ''
  }
  if (name === 'psid') {
    const { lasSettings } = extraProps
    if (lasSettings) {
      return lasSettings.psid
    }
    return LasSettings.flightlines
  }
  if (name === 'default_material') {
    const { visualizationSettings } = extraProps
    if (visualizationSettings && 'default_material' in visualizationSettings) {
      return visualizationSettings.default_material
    }
    return 'None'
  }
  if (name === 'point_size') {
    const { visualizationSettings } = extraProps
    if (visualizationSettings && 'point_size' in visualizationSettings) {
      return visualizationSettings.point_size
    }
    return 0.3
  }
  if (name === 'point_budget') {
    const { visualizationSettings } = extraProps
    if (visualizationSettings && 'point_budget' in visualizationSettings) {
      return visualizationSettings.point_budget
    }
    return 5
  }
  if (name === 'enable_edl') {
    const { visualizationSettings } = extraProps
    if (visualizationSettings && 'enable_edl' in visualizationSettings) {
      return visualizationSettings.enable_edl
    }
    return false
  }
  if (name === 'enable_curves') {
    const { visualizationSettings } = extraProps
    if (visualizationSettings && 'enable_curves' in visualizationSettings) {
      return visualizationSettings.enable_curves
    }
    return false
  }
  if (name === 'enabled_classes') {
    const { visualizationSettings } = extraProps
    const { hasClassifications, classifications } = getClassificationsValues(state, extra)
    if (hasClassifications) {
      if (visualizationSettings && 'enabled_classes' in visualizationSettings) {
        return Object.keys(classifications).filter(index => visualizationSettings.enabled_classes.includes(index))
      }
      return Object.keys(classifications)
    }
    if (visualizationSettings && 'enabled_classes' in visualizationSettings) {
      return visualizationSettings.enabled_classes
    }
    return Object.keys(getClassifications(state))
  }
}

// Local deps

export const getCompanyCalibrationsForCurrentProject = state => {
  const loggedUser = getLoggedUser(state)
  const currentProject = getCurrentProject(state)
  if (currentProject.project.userId !== loggedUser.id) {
    const activeUser = getActiveUser(state)
    const activeUserCompanyId = getCompanyId(activeUser)
    return getCompanyCalibrations(state, activeUserCompanyId)
  } else {
    const companyId = getCompanyId(loggedUser)
    return getCompanyCalibrations(state, companyId)
  }
}
export const getAntennaOption = (antenna, index) => {
  const { name, pco, radome, ...other } = antenna
  return name === 'Generic'
    ? antennaInitialValue
    : {
      name: `${name}${pco ? ` (${withRounding((pco / 1000).toString(), 3)}m${radome ? `, ${radome}` : ''})` : ''}`,
      value: name,
      searchValue: `${name}${index}`,
      radome,
      ...other,
    }
}

export const getAntennaOptions = state => getAntennas(state).map(getAntennaOption)

/**
 * Return overlapping area of vertical and horizontal coordinate systems
 * @param {number} x1 - left bottom x coordinate
 * @param {number} y1 - left bottom y coordinate
 * @param {number} x2 - right top x coordinate
 * @param {number} y2 - right top y coordinate
 * @param {number} x3 - left bottom x coordinate
 * @param {number} y3 - left bottom y coordinate
 * @param {number} x4 - right top x coordinate
 * @param {number} y4 - right top y coordinate
 */
export const getCRSOverlapArea = (x1, y1, x2, y2, x3, y3, x4, y4) => {
  const left = Math.max(x1, x3)
  const right = Math.min(x2, x4)
  const top = Math.max(y2, y4)
  const bottom = Math.min(y1, y3)

  const width = right - left
  const height = top - bottom

  // Return 0 for invalid area
  if (width < 0 || height < 0) {
    return 0
  }

  return width * height
}

export function getGcpCoordinate (state, value, values, extra, coordinate) {
  const points = getGCPsForArtifact(state, extra) || []
  const [firstPoint] = points
  return firstPoint ? firstPoint[coordinate] + '' : ''
}

export function coordinateSystemFieldDisabled (state, formValues) {
  return !isCustomAnalyzeSelected(formValues)
}

export function coordinateFieldDisabled (_state, formValues) {
  return !isCustomAnalyzeSelected(formValues)
}

// Custom position with predefined values aka 'company positions'
export function isUserCustomAnalyzeTypeSelected (formValues) {
  return path(['analyze', 'type'], formValues) === 'custom' &&
    path(['analyze', 'analyzeMode'], formValues) !== 'custom'
}

export function isCustomAnalyzeSelected (formValues) {
  return path(['analyze', 'type'], formValues) === 'custom' || typeof path(['analyze'], formValues) !== 'object'
}

export function isAnalyzedTypeSelected (formValues) {
  return path(['analyze', 'type'], formValues) === 'analyzed'
}

// Concrete 'custom' position
export function isCustomAnalyzeTypeSelected (formValues) {
  return path(['analyze', 'type'], formValues) === 'custom' &&
    path(['analyze', 'analyzeMode'], formValues) === 'custom'
}

export const getTransformedField =
(
  userCustomPositionCallback,
  analyzedTypeDataInitialValue,
  analyzedPositionCallback,
  customAnalyzedPositionCallback,
  customUserCustomPositionCallback,
) => (
  original,
  state,
  formValues,
  extra,
  formTemplate,
  name,
  oldValues,
) => {
  const analyze = formValues[name]
  const analyzeData = analyze && (analyze.data || analyze)
  const analyzedTypeDataValue = typeof analyzedTypeDataInitialValue === 'function'
    ? analyzedTypeDataInitialValue(original, state, formValues, extra, formTemplate, name, oldValues, analyzeData)
    : typeof analyzedTypeDataInitialValue !== 'undefined'
      ? analyzedTypeDataInitialValue
      : original
  // If we select user custom position
  if (isUserCustomAnalyzeTypeSelected(formValues) && analyzeData) {
    return typeof userCustomPositionCallback === 'function'
      ? userCustomPositionCallback(original, state, formValues, extra, formTemplate, name, oldValues, analyzeData)
      : analyzedTypeDataValue
  }
  // If we selected one of predefined positions
  if (isAnalyzedTypeSelected(formValues)) {
    return typeof analyzedPositionCallback === 'function'
      ? analyzedPositionCallback(original, state, formValues, extra, formTemplate, name, oldValues, analyzeData)
      : analyzedTypeDataValue
  }
  // If we choose 'Custom' position
  if (isCustomAnalyzeTypeSelected(formValues)) {
    // And the previous one was one of the predefined positions
    if (isAnalyzedTypeSelected(oldValues)) {
      return typeof customAnalyzedPositionCallback === 'function'
        ? customAnalyzedPositionCallback(original, state, formValues, extra, formTemplate, name, oldValues, analyzeData)
        : analyzedTypeDataValue
    } else {
      if (isCustomAnalyzeSelected(formValues)) {
        return typeof customUserCustomPositionCallback === 'function'
          ? customUserCustomPositionCallback(original, state, formValues, extra, formTemplate, name, oldValues, analyzeData)
          : original
      }
    }
  }
}

export function getTransformedEpoch () {
  return getTransformedField(
    (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
      const value = analyzeData[CRSFields.EPOCH]
      return value ? fromDecimalYear(value) : null
    },
    (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
      let epoch = path(['analyze', CRSFields.EPOCH], formValues) || formValues[CRSFields.EPOCH]
      epoch = typeof epoch === 'object' ? toDecimalYear(epoch) : epoch
      return epoch ? fromDecimalYear(epoch) : null
    },
  )
}

export const updateProjWktString = (values, crsInfo = {}) => {
  return {
    ...values,
    [CRSFields.CUSTOM_CRS]: path(['proj_json'], crsInfo) || '',
  }
}

export function selectFromAnalyzed (original, state, formValues, artifactId, coordinate) {
  if (isCustomAnalyzeSelected(formValues)) {
    return original
  }
  // const analyze = formValues.analyze
  // const analyzeEntry = createAnalyzeEntryMapping(state, artifactId)
  // const selectedEntry = analyzeEntry.find(entry => entry.description === analyze)
  const selectedEntry = formValues.analyze
  const coordinateValue = path(Array.isArray(coordinate) ? coordinate : [coordinate], selectedEntry)
  return `${coordinateValue}`
}

export function getArtifactProperties (state, artifactId) {
  const artifacts = getArtifacts(state)
  const artifact = findById(artifactId, artifacts)
  return path(['properties'], artifact)
}

export function getValueFromArtifact (state, artifactId, pathInProperties) {
  const properties = getArtifactProperties(state, artifactId)
  return path(pathInProperties, properties)
}

export const getNavRoverInitialValues = (state, cbFromSettingsFile, cbFromArtifact) => {
  const { plp = {} } = getImportWizard(state, ['plp'])
  if (plp['settingsFiles']) {
    return cbFromSettingsFile()
  } else {
    return cbFromArtifact()
  }
}

export function getValueFromSettingsFile (state, pathInProperties) {
  const { plp = {} } = getImportWizard(state, ['plp'])
  return path(pathInProperties, plp['settingsFiles'])
  /*
  // Imu orientation
  ['settingsRover', 'navigationSystem', 'imu', 'orientation', 'x']
  ['settingsRover', 'navigationSystem', 'imu', 'orientation', 'y']
  ['settingsRover', 'navigationSystem', 'imu', 'orientation', 'z']
  // Antenna 1 offset
  ['settingsRover', 'navigationSystem', 'offsets', 'ant1', 'translation', 'x']
  ['settingsRover', 'navigationSystem', 'offsets', 'ant1', 'translation', 'y']
  ['settingsRover', 'navigationSystem', 'offsets', 'ant1', 'translation', 'z']
  // Antenna 2 offset
  ['settingsRover', 'navigationSystem', 'offsets', 'ant2', 'translation', 'x']
  ['settingsRover', 'navigationSystem', 'offsets', 'ant2', 'translation', 'y']
  ['settingsRover', 'navigationSystem', 'offsets', 'ant2', 'translation', 'z']
  */
}

const getInitialValue = (state, extra, options, initialValue) => {
  const value = typeof initialValue !== 'undefined'
    ? typeof initialValue === 'function'
      ? initialValue(state, extra, options)
      : initialValue
    : undefined
  return value
}

export const getCloneJobOptionValue = (state, extra, options, fieldName, initialValue) => {
  const { extraProps, formTemplate = {} } = options
  const { clone } = extraProps
  if (!clone) {
    return getInitialValue(state, extra, options, initialValue)
  }
  const jobOptions = getCloneJobOptions(state, extra.templateJobId)
  const value = jobOptions && jobOptions[fieldName]
  return getTransformedClonedValue(value, state, extra, options, initialValue, formTemplate[fieldName], jobOptions)
}

export const getCloneArtifactOptionValue = (state, extra, options, fieldName, initialValue) => {
  const { extraProps, formTemplate = {} } = options
  const { clone, selectedArtifactCloneId } = extraProps || {}
  if (!clone) {
    return getInitialValue(state, extra, options, initialValue)
  }
  const artifactOptions = getCloneArtifactOptions(state, selectedArtifactCloneId)
  const value = artifactOptions && artifactOptions[fieldName]
  return getTransformedClonedValue(value, state, selectedArtifactCloneId, options, initialValue, formTemplate[fieldName], artifactOptions)
}

const getTransformedClonedValue = (value, state, extra, options, initialValue, option, jobOptions) => {
  const { mapping, useMappingOnClone, transformOnClone } = option || {}
  const transformValue = value => {
    return typeof value === 'number'
      ? value.toString()
      : Array.isArray(value)
        ? value.map(safeToString)
        : value
  }
  const transformedValue = typeof value === 'undefined' || value === null
    ? typeof initialValue !== 'undefined'
      ? typeof initialValue === 'function'
        ? initialValue(state, extra, options, jobOptions)
        : initialValue
      : undefined
    : useMappingOnClone && mapping
      ? Object.keys(mapping).find(key => mapping[key] === value) || transformValue(value)
      : transformValue(value)
  return typeof transformOnClone === 'function' ? transformOnClone(value, state, extra, options) : transformedValue
}

export function safeToString (a) {
  return typeof a === 'undefined' ? undefined : String(a)
}

export const isEllipsoidal = (state, values) => {
  return values[CRSFields.IS_ELLIPSOIDAL]
}

export const getTrajectoryArtifacts = state => {
  const selectedTrajectoryArtifacts = getSelectedTrajectoryArtifacts(state)
  const currentProject = getCurrentProject(state)
  const { artifacts } = currentProject
  return artifacts.filter(artifact => findById(artifact.id, selectedTrajectoryArtifacts))
}

export const isTrajectoryArtifactHasSameIMUOrientation = artifacts => {
  return areSameIMUOrientations(artifacts.map(artifact => getArtifactIMUOrientation(artifact)))
}

export const areSameIMUOrientations = imuOrientations => {
  if (imuOrientations.length <= 0 || imuOrientations.some(imuOrientation => !imuOrientation)) return false
  for (let i = 0; i < imuOrientations.length; i++) {
    for (let j = 0; j < imuOrientations.length; j++) {
      if (i !== j && !equals(imuOrientations[i], imuOrientations[j])) {
        return false
      }
    }
  }
  return true
}

export const getImuOrientation = (state, artifactId, options) => {
  const { extraProps, imuOrientation } = options
  if (imuOrientation) {
    return imuOrientation
  }
  const { clone } = extraProps
  let orientation
  let found = false
  if (clone) {
    const imuOrientation = getCloneJobOptionValue(state, artifactId, options, 'orientation')
    if (imuOrientation) {
      orientation = imuOrientation
      found = true
    }
  }
  const trajectoryArtifacts = getTrajectoryArtifacts(state)
  const isSameIMU = isTrajectoryArtifactHasSameIMUOrientation(trajectoryArtifacts)
  if (isSameIMU && !found) {
    const [firstTrajectoryArtifact] = trajectoryArtifacts
    orientation = firstTrajectoryArtifact.properties.imu_orientation
    found = true
  }
  if (!found) {
    // If IMU orientations are not the same we should find first artifact with defined orientation
    const firstTrajectoryArtifactWithIMU = trajectoryArtifacts.find(artifact => isArtifactHasIMUOrientation(artifact))
    if (firstTrajectoryArtifactWithIMU) {
      orientation = firstTrajectoryArtifactWithIMU.properties.imu_orientation
      found = true
    }
  }
  return orientation
}

/**
 * Adds or updates selected option in the cookie. If the option already exists, it is moved to the front of the list.
 * The list is truncated to the last 5 entries and saved back to the cookies.
 *
 * @param {Object} value - The new option object to be added or updated.
 * @param {Number} amount - Max number of items for saving in cookies.
 * @param {String} name - Cookies name.
 */
export function addFavoriteOptionToCookie(value, amount, name) {
  let values = Cookies.get(name) ? JSON.parse(Cookies.get(name)) : []

  const index = values.findIndex(item => item.name === value.name)
  if (index !== -1) {
    values.splice(index, 1)
  }

  if (value.name !== '') {
    values.unshift({ name: value.name, searchValue: value.searchValue, radome: value.radome });
  }
  values = values.slice(0, amount)
  Cookies.set(name, JSON.stringify(values), { expires: 30 })
}

/**
 * Retrieves a list of item options for display. The list prioritizes recently selected options,
 * followed by all other available options.
 *
 * @param {Object} state - The current state context used to fetch all available items options.
 * @param {String} name - Cookies key name.
 * @returns {Array} - An array containing the combined list of recently selected and remaining items.
 */
export function getFavouriteOptions(state, name) {
  const lastSelectedOptionsData = Cookies.get(name) ? JSON.parse(Cookies.get(name)) : []
  const allAvailableOptions = getAntennaOptions(state)
  // Use a Set to track unique options
  const uniqueOptions = new Set();

  // Get all options for each antenna in lastSelectedOptions and maintain order
  const lastSelectedAllOptions = lastSelectedOptionsData.flatMap(option =>
    allAvailableOptions.filter(selectedOption => {
      if (
        selectedOption.name === option.name &&
        selectedOption.searchValue === option.searchValue &&
        selectedOption.radome === option.radome &&
        !uniqueOptions.has(JSON.stringify(selectedOption))
      ) {
        uniqueOptions.add(JSON.stringify(selectedOption));
        return true;
      }
      return false;
    })
  );
  const restOptions = allAvailableOptions.filter(option => !lastSelectedAllOptions.some(selectedOption => selectedOption.name === option.name
    && selectedOption.searchValue === option.searchValue)
  )
  return [...lastSelectedAllOptions, ...restOptions]
}

/**
 * Retrieves the IMU orientation from an artifact's properties.
 *
 * @param {Object} artifact - The artifact object to extract the IMU orientation from.
 * @returns {Array|null} The IMU orientation array if it exists, otherwise `null`.
 */
export function getArtifactIMUOrientation(artifact) {
  if (!artifact || !artifact.properties || !artifact.properties.imu_orientation) {
    return null
  }
  return artifact.properties.imu_orientation
}

/**
 * Checks whether the artifact has a valid IMU orientation.
 *
 * @param {Object} artifact - The artifact object to check.
 * @returns {boolean} `true` if the artifact has a valid IMU orientation, otherwise `false`.
 */
export function isArtifactHasIMUOrientation(artifact) {
  const imuOrientation = getArtifactIMUOrientation(artifact)
  return Array.isArray(imuOrientation)
}